import { getTestPort } from "@/utils/common";
const VUE_APP_URL_API = getTestPort();
const store = {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async createWarehouse({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/warehouse/createWarehouse", params);
      return data;
    },
    async updateWarehouse({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/warehouse/updateWarehouse", params);
      return data;
    },
    async getWarehouseList({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/warehouse/queryOpWarehouseList", params);
      return data;
    }
  }
};

export default store;
