















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { t } from "@src/locale";

@Component({
  inheritAttrs: false
})
export default class SingleSearch extends Vue {
  static componentName = "SbSingleSearch";
  @Prop({ default: () => t("sb.please-enter-the-content") })
  placeholder: string;
  search() {
    this.$emit("search");
  }
}
