import { getTestPort } from "@/utils/common";
const VUE_APP_URL_API = getTestPort();
const store = {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // 获取客户列表
    async getClientList({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/car/sale/onlyClue/list", params);
      return data;
    },
    // 获取客户列表数量
    async getClientStageCount({ rootState, commit }, params) {
      params.method = "get";
      const data = await rootState.http(VUE_APP_URL_API + "/car/sale/customer/stageCount", params);
      return data;
    },
    // 获取客户线索列表
    async getClientClueList({ rootState, commit }, params) {
      params.method = "get";
      const data = await rootState.http(VUE_APP_URL_API + "/car/sale/customer/clue/list", params);
      return data;
    },
    // 获取唯一线索详情
    async getClientDetail({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/car/sale/onlyClue/detail", params);
      return data;
    },
    // 获取客户试驾列表
    async getClientDriveList({ rootState, commit }, params) {
      params.method = "get";
      const data = await rootState.http(VUE_APP_URL_API + "/car/sale/customer/diver/list", params);
      return data;
    },
    // 获取客户客流列表
    async getClientFlowList({ rootState, commit }, params) {
      params.method = "get";
      const data = await rootState.http(VUE_APP_URL_API + "/car/sale/customer/flow/list", params);
      return data;
    },
    // 获取客户旅程列表
    async getClientItineraryList({ rootState, commit }, params) {
      params.method = "get";
      const data = await rootState.http(VUE_APP_URL_API + "/car/sale/clue/log/pageList", params);
      return data;
    },
    // 获取客户订单发票
    async getClientOrderInvoice({ rootState, commit }, params) {
      params.method = "get";
      const data = await rootState.http(VUE_APP_URL_API + "/car/sale/customer/order/invoice/detail", params);
      return data;
    },
    // 获取客户订单列表
    async getClientOrderList({ rootState, commit }, params) {
      params.method = "get";
      const data = await rootState.http(VUE_APP_URL_API + "/car/sale/customer/order/pageList", params);
      return data;
    },
    // 更新数据
    async getClientUpdate({ rootState, commit }, params) {
      params.method = "get";
      const data = await rootState.http(VUE_APP_URL_API + "/car/sale/batch/crawl", params);
      return data;
    },

    async getCarSaleOrderUpdate({ rootState, commit }, params) {
      params.method = "get";
      const data = await rootState.http(VUE_APP_URL_API + "/car/sale/order/batch/crawl", params);
      return data;
    },

    // 获取新车订单列表
    async getCarSaleOrderList({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/car/sale/order/pageList", params);
      return data;
    },

    // 获取新车订单列表
    async getCarSaleOrderDetail({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/car/sale/order/detail/" + params.orderCode, params);
      return data;
    },

  }
};

export default store;
