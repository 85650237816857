import { getTestPort } from "@/utils/common";
const VUE_APP_URL_API = getTestPort();
const store = {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async queryCampaignList({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/campaign/solicit/queryList", params);
      return data;
    },
    async updateCampaignBeEnd({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/campaign/solicit/stopCampaign", params);
      return data;
    }
  }
};

export default store;
