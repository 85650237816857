import { getTestPort } from "@/utils/common";
const VUE_APP_URL_API = getTestPort();
const store = {
  namespaced: true,
  state: { filterProjectPartsDialog: false },
  getter: {},
  mutations: {
    setFilterProjectPartsDialog(state, data) {
      state.filterProjectPartsDialog = data;
    }
  },
  actions: {
    // 裂变单退款
    async refundOrder({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opReceipt/fission/refundOrder", params);
      return data;
    },
    // 结算账单列表
    async settlement({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opReceipt/fission/settlement", params);
      return data;
    },
    // 查询账单
    async queryOpReceiptPage({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opReceipt/fission/queryOpReceiptPage", params);
      return data;
    },
    async updateFission({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opCampaign/updateFission", params);
      return data;
    },
    async queryFissionCampaign({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opCampaign/queryFissionCampaign", params);
      return data;
    },
    async synchronizeStores({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opCampaign/synchronizeStores", params);
      return data;
    },
    async insertFission({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opCampaign/insertFission", params);
      return data;
    },
    async queryFissionCampaignDetail({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opCampaign/queryFissionCampaignDetail", params);
      return data;
    },
    async statisticCampaign({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opCampaign/statisticCampaign", params);
      return data;
    },
    async queryOpFissionPage({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opCampaign/queryOpFissionPage", params);
      return data;
    },
    async getServiceItemPage({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/staff/commission/getServiceItemPage", params);
      return data;
    },
    async getPartsPage({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/staff/commission/getPartsPage", params);
      return data;
    }
  }
};
export default store;
