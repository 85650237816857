import { getTestPort } from "@/utils/common";
const VUE_APP_URL_API = getTestPort();
const store = {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    //新增公告
    async addAnnouncement({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/announcement/createAnnouncement", params);
      return data;
    },
    //获取公告列表
    async getAnnouncementList({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/announcement/queryAnnouncementList", params);
      return data;
    },
    //结束公告
    async endAnnouncement({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/announcement/endAnnouncement", params);
      return data;
    },
    //编辑公告
    async editAnnouncement({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/announcement/updateAnnouncement", params);
      return data;
    }
  }
};

export default store;
