















import { Component, Prop, Vue } from "vue-property-decorator";
import { t } from "../../src/locale";

@Component({
  inheritAttrs: false
})
export default class SbDialog extends Vue {
  static componentName = "SbDialog";
  @Prop({ default: "480px" })
  width: string;
  @Prop({ default: "" })
  content: string;
  @Prop({ default: true })
  showCancel: string;
  @Prop({ default: true })
  showConfirm: string;
  @Prop({ default: () => t("sb.take-away") })
  cancelText: string;
  @Prop({ default: () => t("sb.sure") })
  confirmText: string;
  @Prop({ type: Boolean, default: false })
  hideFooter;
  @Prop({ type: Boolean, default: false })
  confirmBtnLoading;
  @Prop()
  cancel: any;
  handleClose() {
    if (this.$listeners.cancel) {
      this.$emit("cancel");
    } else {
      this.$emit("update:visible", false);
    }
  }
  confirm() {
    this.$emit("confirm");
  }
}
