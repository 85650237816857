import { getTestPort } from "@/utils/common";
const VUE_APP_URL_API = getTestPort();
const store = {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getRevisitComment({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/revisit/getRevisitComment", params);
      return data;
    },
    async commentRevisit({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/revisit/commentRevisit", params);
      return data;
    },
    // 门店员工
    async queryStaffAccountList({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/staff/queryStaffAccountList", params);
      return data;
    },
    async queryRevisitCommentList({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/revisit/queryRevisitCommentList", params);
      return data;
    },
    async getRevisitRule({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/revisit/getRevisitRule", params);
      return data;
    },
    async saveRevisitRule({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/revisit/saveRevisitRule", params);
      return data;
    },
    async batchManualPush({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/maintainRemindRecord/batchManualPush", params);
      return data;
    },
    async queryRemindRecordPage({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/maintainRemindRecord/queryRemindRecordPage", params);
      return data;
    },
    async createRemindSetting({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/maintainRemindSetting/createRemindSetting", params);
      return data;
    },
    async queryRemindSetting({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/maintainRemindSetting/queryRemindSetting", params);
      return data;
    },
    async createPayment({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opCardTemplate/addCardTemplate", params);
      return data;
    },
    async createCardTemplate({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opCardTemplate/addCardTemplate", params);
      return data;
    },
    async queryCardTemplateList({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opCardTemplate/queryList", params);
      return data;
    },
    async updateCardTemplateStatus({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opCardTemplate/enable", params);
      return data;
    },

    async updateCardTemplate({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opCardTemplate/editCardTemplate", params);
      return data;
    },
    async queryCardTemplateDetail({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opCardTemplate/getCardTemplate", params);
      return data;
    },
    async createCard({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opCard/addCard", params);
      return data;
    },
    async queryCardList({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opCard/queryList", params);
      return data;
    },
    async queryCardDetail({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opCard/getCard", params);
      return data;
    },
    async queryEnableCardListByService({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opCard/queryEnableList", params);
      return data;
    },
    async queryMiniProgramQrByStore({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/storeSetting/findWxQrCode", params);
      return data;
    },
    async updateStoreInfoByWeChat({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/store/saveStoreBasicInfo", params);
      return data;
    },
    async queryStoreInfoByWeChat({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/store/getStoreBasicInfo", params);
      return data;
    },
    async validateAddCard({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opCard/validateAddCard", params);
      return data;
    },
    async updateCardStatusRefund({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opCard/returnCard", params);
      return data;
    },
    async editIsShow({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/opCardTemplate/editShow", params);
      return data;
    },
    // 活动保存
    async newActivitySave({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/activity/save", params);
      return data;
    },
    // 活动详情
    async newActivityDetail({ rootState, commit }, params) {
      params.method = "get";
      const data = await rootState.http(VUE_APP_URL_API + `/op/activity/${params.activityCode}/detail`, params);
      return data;
    },
    // 活动推广列表
    async newActivityPopularizeList({ rootState, commit }, params) {
      params.method = "get";
      const data = await rootState.http(VUE_APP_URL_API + "/op/activity/link/pageList", params);
      return data;
    },
    // 活动列表
    async newActivityList({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/activity/pageList", params);
      return data;
    },
    // 活动推广创建
    async newActivityPopularizeCreate({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/activity/link/create", params);
      return data;
    },

    // 活动数据分析概览
    async newActivityAnalysis({ rootState, commit }, params) {
      params.method = "get";
      const data = await rootState.http(VUE_APP_URL_API + `/op/activity/${params.activityCode}/overview`, params);
      return data;
    },

    // 活动参与明细列表
    async newAnalysisDetailList({ rootState, commit }, params) {
      params.method = "get";
      const data = await rootState.http(VUE_APP_URL_API + `/op/activity/order/pageList`, params);
      return data;
    },
    // 活动参与明细
    async newAnalysisDetail({ rootState, commit }, params) {
      params.method = "get";
      const data = await rootState.http(VUE_APP_URL_API + `/op/activity/order/detail/` + params.orderNo, params);
      return data;
    },
    // 活动参与明细
    async newAnalysisRefund({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + `/op/activity/order/refund`, params);
      return data;
    },

    // 活动渠道明细
    async newAnalysisChannelList({ rootState, commit }, params) {
      params.method = "get";
      const data = await rootState.http(VUE_APP_URL_API + `/op/activity/link/data/pageList`, params);
      return data;
    },

    // 活动用户裂变明细
    async newAnalysisShareList({ rootState, commit }, params) {
      params.method = "get";
      const data = await rootState.http(VUE_APP_URL_API + `/op/activity/share/data/pageList`, params);
      return data;
    },

    // 活动推广链接绑定手机号
    async activityBindMobile({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + `/op/activity/link/mobile/bind`, params);
      return data;
    },

    async getCommissionConf({ rootState, commit }, params) {
      params.method = "get";
      const data = await rootState.http(VUE_APP_URL_API + "/op/activity/commission/config/" + params.activityCode, params);
      return data;
    },

    async saveCommissionConf({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/activity/commission/config/save", params);
      return data;
    },

    async publishActivity({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/activity/publish/"+ params.activityCode, params);
      return data;
    },

    async closeActivity({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/activity/close/"+ params.activityCode, params);
      return data;
    },

    async returnDraftActivity({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/activity/return/draft/"+ params.activityCode, params);
      return data;
    },
  }
};

export default store;
