















































































































import { Component, Prop, Watch, Mixins } from "vue-property-decorator";
import Locale from "@src/mixins/locale";
import { t } from "@src/locale";
import AreaSelect from "../area-select/index.vue";

@Component({
  components: { AreaSelect }
})
export default class SbSearchBar extends Mixins(Locale) {
  static componentName = "SbSearchBar";
  @Prop({
    default: () => []
  })
  data: any;

  @Prop({ default: false, type: Boolean })
  defaultExpand: boolean;

  @Prop({
    default: 4
  })
  showItemNum: number;

  @Prop({ default: () => {} })
  getAreaMethod: any;

  selfData: any = [];
  openMore: boolean = false;
  total: number = 0;
  copyData: any = [];
  created() {
    if (this.defaultExpand) {
      this.showItemNum = this.data.length;
    }
    if (this.showItemNum < this.data.length) {
      this.openMore = false;
    } else {
      this.openMore = true;
    }
    this.initSelfData(this.data);
  }
  initSelfData(list) {
    this.total = list.length;
    this.copyData = list.slice().filter(item => item.prop);
    let tempArr = [];
    if (this.openMore) {
      tempArr = list.slice();
    } else {
      tempArr = list.slice(0, this.showItemNum);
    }
    // 重新渲染保留旧的value
    if (this.oldData) {
      tempArr = tempArr.map((item, index) => {
        item.value = this.oldData[index].value;
        return item;
      });
    }
    let num = tempArr.length < 4 ? 4 - tempArr.length : 4 - (tempArr.length % 4);
    while (num > 1) {
      tempArr.push({});
      num--;
    }
    this.selfData = tempArr;
  }
  search() {
    this.$emit("search", this.formatListData());
  }
  reset() {
    this.$emit("reset", this.formatListData(true));
  }
  formatListData(reset = false) {
    const listData = [];
    for (let i = 0; i < this.selfData.length; i++) {
      const itemData = this.selfData[i];
      if (itemData.prop) {
        if (reset) {
          itemData.value = "";
          itemData.labels && (itemData.labels = []);
        }
        listData.push(itemData);
        if (itemData.valueDistribute) {
          itemData.valueDistribute.forEach((key, index) => {
            listData.push({
              prop: key,
              value: reset ? "" : itemData.value[index]
            });
          });
        }
      }
    }
    return listData;
  }

  handleMore() {
    this.openMore = !this.openMore;
    this.initSelfData(this.copyData);
  }
  handleAreaChange(val, item) {
    this.$nextTick(() => {
      this.$nextTick(() => {
        const labels = (this.$refs["refAareSelect"] as any)[0].getLabels();
        item.labels = labels;
      });
    });
  }
  oldData: any = null;
  @Watch("data")
  watchData(val, oldVal) {
    this.oldData = oldVal;
    val && this.initSelfData(val);
  }
}
