import { getTestPort } from "@/utils/common";

const VUE_APP_URL_API = getTestPort();
const store = {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getVehicleInfoByModeCode({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/vehicleMatching/getVehicleMatchingInfo", params);
      return data;
    }
  }
};

export default store;
