






















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
@Component({
  inheritAttrs: false
})
export default class SbPagination extends Vue {
  static componentName = "SbPagination";
  @Prop({ default: "total, sizes, prev, pager, next, jumper" })
  layout: string;
  @Prop({ default: false })
  check: boolean;
  @Prop({ default: () => [] })
  selectedRows: any[];
  @Prop({ default: () => {} })
  dataList: any[];
  isIndeterminate: boolean = false;
  checkAll: boolean = false;
  pageSize: number = 10;
  handleSizeChange(size) {
    this.$emit("sizeChange", size);
  }
  handlePageChange(pageNum) {
    this.$emit("pageChange", pageNum);
  }
  handleCheckAllChange(val) {
    this.checkAll = val;
    this.isIndeterminate = false;
    if (val) {
      this.$emit("checkAll");
    } else {
      this.$emit("cancelAll");
    }
  }
  @Watch("selectedRows")
  watchSelectedRows(val) {
    this.checkAll = val.length === this.dataList.length ? true : false;
    this.isIndeterminate = val.length > 0 && val.length < this.dataList.length ? true : false;
  }
}
