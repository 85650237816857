export default {
  install: function(Vue) {
    // 引入svg图片
    const requireAll = requireContext => requireContext.keys().map(requireContext);
    const req = require.context("@/assets/icon", true, /\.svg$/);
    requireAll(req);
    // key: 组件名称，value：vue文件名称
    const f = {
      "se-headline": "headline",
      "svg-icon": "svg-icon",
      "se-input-phone": "input-phone",
      "se-pagination": "pagination",
      "se-breadcrumb": "breadcrumb",
      "se-data-empty": "data-empty",
      "se-input-password": "input-password",
      "se-table-empty": "table-empty",
      "se-filter-bar": "filter-bar",
      "se-tips-dialog": "tips-dialog",
      "se-input-carnum": "input-carnum",
      "se-input-unit": "input-unit",
      "se-single-search": "single-search",
      "se-btn-loading": "btn-loading",
      "se-upload": "upLoad",
      "se-capsule-text": "capsule-text",
      "se-footer-btns": "footer-btns",
      "my-table": "my-table",
      "custom-editor": "customEditor"
    };
    Object.keys(f).forEach(item => {
      Vue.component(item, () => import(`./${f[item]}.vue`));
    });
  }
};
