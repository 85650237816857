







import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  inheritAttrs: false
})
export default class SbInputUnit extends Vue {
  static componentName = "SbInputUnit";
  @Prop({ default: "单位" })
  unitName: string;
  @Prop({ default: "" })
  unitType: string;
}
