// 这个存放纯工具函数，不可引入vuex等和业务相关的module
/**
 * 千位分割
 * @param {number | string} num
 * @return {string}
 */
export function splitThousands(num) {
  if (!num && num !== 0) {
    return num;
  }
  const flag = parseFloat(num) < 0 ? "-" : "";
  let numStr = String(num);
  const end = numStr.split(".")[1] ? "." + numStr.split(".")[1] : "";
  const arr = [...String(Math.abs(parseInt(num)))];
  let result = "";
  while (arr.length > 3) {
    result = "," + arr.splice(-3).join("") + result;
  }
  return flag + arr.join("") + result + end;
}

/**
 * 除了0 所有能转换为false的都用“-”代替
 * @param {number} val 目标值
 * @param {number} showValue  val格式化后的值
 * @return {string}
 */
export function emptyToLine(val, showValue?) {
  return val || val === 0 ? (showValue ? showValue : val) : "-";
}

/**
 * 生成uuid
 * @param {number} len 长度
 * @param {number} radix 基数(范围)
 * @return {string}
 */
export function getUuid(len, radix?) {
  let chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split("");
  let uuid = [],
    i;
  radix = radix || chars.length;
  if (len) {
    for (i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)];
  } else {
    let r;
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = "-";
    uuid[14] = "4";
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | (Math.random() * 16);
        uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r];
      }
    }
  }
  return uuid.join("");
}

/**
 * 8位日期格式化 20200508 => 2020-05-08
 * @param {number} date 日期
 * @return {string}
 */
export function numDateToDate(date) {
  if (!date) {
    return "-";
  }
  return date.substr(0, 4) + "-" + date.substr(4, 2) + "-" + date.substr(6, 2);
}

/**
 * 获取图片的高度和宽度
 * @param {Element} imgEl
 * @return {Array} [width, height]
 */
export function getImageSize(imgEl) {
  let width: number = 0,
    height: number = 0;
  height = imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height;
  width = imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width;
  return [width, height];
}

// 安全获取对象属性
export function safeValue(obj, keyStr, placeholder: any = "") {
  if (!obj) return placeholder;
  const keyArr = keyStr.split(".");
  let curValue = obj;
  for (let i = 0; i < keyArr.length; i++) {
    let temp = curValue[keyArr[i]];
    if (!temp) {
      console.warn(keyStr + " of undefined");
      return placeholder;
    }
    curValue = temp;
  }
  return curValue;
}

/**
 *根据域名获取国家地区
 * @returns Country flag
 */
export function getCountry() {
  const host = window.location.host;
  const reg = /speedbiz(\.|-|[0-9a-zA-Z])*(\.|-)my/;
  if (reg.test(host)) {
    return "my";
  } else {
    return "china";
  }
}
