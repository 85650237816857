import { getTestPort } from "@/utils/common";
const VUE_APP_URL_API = getTestPort();
const store = {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    //  营业额 列表
    async getTurnOverPageModelPage({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/report/turnOver/getTurnOverPageModelPage", params);
      return data;
    },
    //  营业额 图表
    async getTurnOverAnalysisModel({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/report/turnOver/getTurnOverAnalysisModel", params);
      return data;
    },
    //  营业额 导出
    async exportTurnOverPage({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/report/turnOver/exportTurnOverPage", params);
      return data;
    },
    //  卡销售 列表
    async getCardMarketList({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/report/turnOver/getCardTurnOverPageModelPage", params);
      return data;
    },
    //  客情 列表
    async getCustomerSituationPageModelPage({ rootState }, params = {}) {
      const data = await rootState.http(
        VUE_APP_URL_API + "/report/customerSituation/getCustomerSituationPageModelPage",
        params
      );
      return data;
    },
    //  客情 图表
    async getCustomerSituationAnalysisModel({ rootState }, params = {}) {
      const data = await rootState.http(
        VUE_APP_URL_API + "/report/customerSituation/getCustomerSituationAnalysisModel",
        params
      );
      return data;
    },
    //  客情 导出
    async exportCustomerSituationPage({ rootState }, params = {}) {
      const data = await rootState.http(
        VUE_APP_URL_API + "/report/customerSituation/exportCustomerSituationPage",
        params
      );
      return data;
    },
    //  轮胎销售 列表
    async queryTireSalesReport({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/report/queryTireSalesReport", params);
      return data;
    },
    //  轮胎销售 图表 饼图
    async queryTireSalesAnalysis({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/report/queryTireSalesAnalysis", params);
      return data;
    },
    //  轮胎销售 图表 柱状图
    async queryTireSalesMonthAnalysis({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/report/queryTireSalesMonthAnalysis", params);
      return data;
    },
    //  轮胎销售 导出
    async exportTireSalesReport({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/report/exportTireSalesReport", params);
      return data;
    }
  }
};

export default store;
