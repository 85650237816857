import { getTestPort } from "@/utils/common";
const VUE_APP_URL_API = getTestPort();
const store = {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    //分页查询
    async getUserMemberList({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/user/pageUserMember", params);
      return data;
    },

    //查询等级信息
    async getMemberLevelInfo({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/user/member/level/info", params);
      return data;
    },

    //更新等级及权益信息
    async updateMemberLevelInfo({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/update/member/level/info", params);
      return data;
    },

    //更新等级状态
    async updateMemberStateInfo({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/update/member/level/state", params);
      return data;
    },

    //添加等级及权益信息
    async addMemberLevelInfo({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/add/member/level/info", params);
      return data;
    },

    //查询所有等级
    async getMemberLevelList({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/user/member/level/list", params);
      return data;
    },

    //根据手机号查询等级日志
    async getMemberLevelLogList({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/user/pageUserMemberValLog", params);
      return data;
    },

    //根据手机号查询积分账户信息
    async getUserIntegralAccountInfo({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/user/integral/account/info", params);
      return data;
    },

    //根据accountNo查找积分日志
    async getUserIntegralAccountLog({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/user/integral/account/log", params);
      return data;
    }
  }
};

export default store;
