export default {
  sb: {
    "take-away": "Cancel",
    "please-input": "Please input",
    "please-select": "Please select",
    "pack-up-screen": "Pack up screen",
    "more-screening": "More screen",
    sure: "Sure",
    reset: "Reset",
    query: "Query",
    to: "To",
    "start-date": "Start date",
    "end-date": "End date",
    "start-time": "Start time",
    "end-time": "End time",
    "please-enter-the-content": "Please input content",
    "upload-video": "Upload video",
    "please-upload-video-file": "Please upload a video file"
  }
};
