export default {
  sb: {
    "take-away": "取 消",
    "please-input": "请输入",
    "please-select": "请选择",
    "pack-up-screen": "收起筛选",
    "more-screening": "更多筛选",
    sure: "确 定",
    reset: "重置",
    query: "查询",
    to: "至",
    "start-date": "开始日期",
    "end-date": "结束日期",
    "start-time": "开始时间",
    "end-time": "结束时间",
    "please-enter-the-content": "请输入内容",
    "upload-video": "上传视频",
    "please-upload-video-file": "请上传视频",
    "only-support-pm4": "仅支持上传MP4格式的视频文件"
  }
};
