
















import { Component, Prop, Vue } from "vue-property-decorator";
@Component({})
export default class SbBreadcrumb extends Vue {
  static componentName = "SbBreadcrumb";
  @Prop({ type: Boolean })
  showBottomBorder: boolean;
  @Prop({
    default: () => [
      {
        name: "首页"
      },
      {
        name: "个人中心",
        path: "/"
      }
    ]
  })
  itemData: [];
}
