import { getTestPort } from "@/utils/common";
const VUE_APP_URL_API = getTestPort();
const store = {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getClientList({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/bx/clue/list", params);
      return data;
    },

    async getClientDetail({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/bx/clue/detail/"+ params.clueId,{});
      return data;
    },

    async getLoggerPageList({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/bx/clue/logger/list", params);
      return data;
    },

    async addNewBxClue({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/bx/clue/add", params);
      return data;
    },
    async followedBxClue({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/bx/clue/followed", params);
      return data;
    },

    async defeatBxClue({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/bx/clue/defeat", params);
      return data;
    },

    async dealBxClue({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/bx/clue/deal", params);
      return data;
    },
    async getCustomerlist({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/bx/clue/customer/list", params);
      return data;
    },
    async getClueCustomerDashboard({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/bx/clue/customer/terminal/dashboard", params);
      return data;
    },
    async getClueCustomerDetail({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/bx/clue/customer/detail/" + params.customerClueId, params);
      return data;
    },
    async getClueStatisticsDashboard({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/bx/clue/statistics/dashboard", params);
      return data;
    },
    async bxClueCustomerEdit({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/bx/clue/customer/edit", params);
      return data;
    },
    async licenseRecognize({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/vehicle/ocr/license/recognize", params);
      return data;
    },

  }
};

export default store;
