import { getTestPort } from "@/utils/common";
const VUE_APP_URL_API = getTestPort();
const detecationTemplate = {
    namespaced: true,
    state: {
        serviceMode: null
    },
    getters: {},
    mutations: {
        setServiceMode(state, data) {
            state.serviceMode = data ? data : null;
        }
    },
    actions: {
        async getDetecationTemplateist({ rootState, commit }, params) {
            params.method = 'get';
            const data = await rootState.http(VUE_APP_URL_API + "/athenaApi/op/detecationTemplate/pageList", params);
            return data;
        },
        getDetecationTemplateDetail({ rootState, commit }, params) {
            params.method = 'get';
            const data = rootState.http(VUE_APP_URL_API + "/athenaApi/op/detecationTemplate/"+ params.templateId +"/detail", params);
            return data;
        },
        async saveDetecationTemplate({ rootState, commit }, params) {
            const data = await rootState.http(VUE_APP_URL_API + "/athenaApi/op/detecationTemplate/save", params);
            return data;
        },
        async publishDetecationTemplate({ rootState, commit }, params) {
            const data = await rootState.http(VUE_APP_URL_API + "/athenaApi/op/detecationTemplate/"+ params.templateId +"/publish", params);
            return data;
        },
        async offShelfDetecationTemplate({ rootState, commit }, params) {
            const data = await rootState.http(VUE_APP_URL_API + "/athenaApi/op/detecationTemplate/"+ params.templateId +"/offShelf", params);
            return data;
        }
    }
};

export default detecationTemplate;
