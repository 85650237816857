import { getTestPort } from "@/utils/common";
const VUE_APP_URL_API = getTestPort();
const setting = {
  namespaced: true,
  state: {
    serviceMode: null
  },
  getters: {},
  mutations: {
    setServiceMode(state, data) {
      state.serviceMode = data ? data : null;
    }
  },
  actions: {
    async getPcPermissionList({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/resource/queryResourceList", params);
      return data;
    },
    async getFunctionList({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/tenant/queryFunctionList", params);
      return data;
    },
    async getRoleList({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/role/queryRoleList", params);
      return data;
    },
    async createRole({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/role/createRole", params);
      return data;
    },
    async updateRole({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/role/updateRole", params);
      return data;
    },
    async getRoleDetail({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/role/getRole", params);
      return data;
    },
    async setOrderMode({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/order/setOrderMode", params);
      return data;
    },
    async getOrderMode({ rootState, state, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/order/getOrderMode", params);
      commit("setServiceMode", data.data);
      return Promise.resolve(state.serviceMode);
    },
    async createStoreType({ rootState, state, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/store/addStoreType", params);
      return data;
    },
    async getPatternPaymentList({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/payMethod/queryList", params);
      return data;
    },
    async getMenuList({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/menu/queryMenuList", params);
      return data;
    },
    async saveMenuAndResource({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/menu/saveMenuAndResource", params);
      return data;
    },

    async delMenuAndResource({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/menu/deleteMenuAndResource", params);
      return data;
    },
    async getMenuByCode({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/menu/getMenu", params);
      return data;
    },
    async updateMenuAndResource({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/menu/updateMenuAndResource", params);
      return data;
    },
  }
};

export default setting;
