import { getTestPort } from "@/utils/common";
const VUE_APP_URL_API = getTestPort();
const store = {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    //新增Banner
    async addbanner({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/banner/createBanner", params);
      return data;
    },
    //获取Banner列表
    async getbannerList({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/banner/queryBannerList", params);
      return data;
    },
    //下架Banner
    async endbanner({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/banner/offShelfBanner", params);
      return data;
    },
    //编辑Banner
    async editbanner({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/banner/updateBanner", params);
      return data;
    },
    //发布Banner
    async pushbanner({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/banner/publishBanner", params);
      return data;
    }
  }
};

export default store;
