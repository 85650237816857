import { getTestPort } from "@/utils/common";
const VUE_APP_URL_API = getTestPort();
const app = {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // 咨询分类
    // 查询资讯分类列表
    async queryContentClassPage({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opContent/queryContentClassPage", params);
      return data;
    },
    // 排序资讯分类
    async changeClassOrder({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opContent/changeClassOrder", params);
      return data;
    },
    // 查询资讯分类是否已存在
    async repeatClassName({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opContent/repeatClassName", params);
      return data;
    },
    // 新增资讯分类
    async insertContentClass({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opContent/insertContentClass", params);
      return data;
    },
    // 删除资讯分类
    async delContClass({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opContent/delContClass", params);
      return data;
    },
    // 编辑资讯分类
    async updateContentClass({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opContent/updateContentClass", params);
      return data;
    },
    // 咨询
    // 查询资讯列表
    async queryContentPage({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opContent/queryContentPage", params);
      return data;
    },
    // 查询资讯列表详情
    async queryContentDetail({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opContent/queryContentDetail", params);
      return data;
    },
    // 查询资讯标题是否存在
    async repeatTitle({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opContent/repeatTitle", params);
      return data;
    },
    // 新增咨询
    async insertContent({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opContent/insertContent", params);
      return data;
    },
    // 编辑咨询
    async updateContent({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opContent/updateContent", params);
      return data;
    },
    // 发布咨询
    async releaseContent({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opContent/releaseContent", params);
      return data;
    },
    // 撤回咨询
    async withdrawContent({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opContent/withdrawContent", params);
      return data;
    },
    // 发布人列表
    async queryUserAccountList({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/userAccount/queryUserAccountList", params);
      return data;
    }
  }
};

export default app;
