import { getTestPort } from "@/utils/common";
const VUE_APP_URL_API = getTestPort();
const maintenance = {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        async getMaintenanceList({ rootState }, params) {
            params.method = 'get';
            const data = await rootState.http(VUE_APP_URL_API + "/athenaApi/op/maintenance/list", params);
            return data;
        },
        async getMaintenanceReportList({ rootState }, params) {
            params.method = 'get';
            const data = await rootState.http(VUE_APP_URL_API + "/athenaApi/op/vehicle/report/list", params);
            return data;
        },
        async getMaintenanceReportItems({ rootState }, params) {
            params.method = 'get';
            const data = await rootState.http(VUE_APP_URL_API + "/athenaApi/op/vehicle/report/"+params.reportNo+"/items", params);
            return data;
        }
    }
};

export default maintenance;
