import { getTestPort } from "@/utils/common";
const VUE_APP_URL_API = getTestPort();
const store = {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async createParts({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opParts/createParts", params);
      return data;
    },
    async getPartsList({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opParts/queryPartsList", params);
      return data;
    },
    async getPartsInventoryList({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/inventory/queryPartsInventory", params);
      return data;
    },
    async getPartsDetail({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opParts/getParts", params);
      return data;
    },
    async updateParts({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opParts/updateParts", params);
      return data;
    },
    async updateStatus({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opParts/updatePartsStatusList", params);
      return data;
    },
    async getPartsBrandList({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opParts/brand/queryBrandList", params);
      return data;
    },
    async createBrand({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opParts/brand/saveBrand", params);
      return data;
    },
    async partsInventoryMove({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/inventory/inventoryMove", params);
      return data;
    }
  }
};

export default store;
