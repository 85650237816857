import Vue from "vue";
import App from "./App.vue";
import i18n from "@common/src/i18n/i18n";
import router from "./router";
import store from "./store";
import VueClipboard from "vue-clipboard2";
import Directive from "@/extends/directive";
import Element from "element-ui";
import SpeedbizUi from "@/speedbiz-ui";
import { safeValue } from "@common/src/utils/common";

import "./assets/css/index.scss";
import "./assets/css/common.scss";
import "./assets/css/element-cover.scss";
import "@common/src/assets/css/element-cover.scss";

import components from "@/components/global/index";
import message from "@/extends/message";
import localMark from "@common/src/extends/local-mark";
import Print from "vue-print-nb";

require("promise.prototype.finally").shim();
Vue.config.productionTip = false;
Vue.prototype.$safeValue = safeValue;
Vue.use(VueClipboard);
Vue.use(components);
Vue.use(Directive);
Vue.use(message);
Vue.use(localMark);
Vue.use(Print);
Vue.use(Element, {
  i18n: (key, value) => i18n.t(key, value)
});
Vue.use(SpeedbizUi, {
  i18n: (key, value) => i18n.t(key, value)
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
