import { getTestPort } from "@/utils/common";
const VUE_APP_URL_API = getTestPort();
const store = {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async createSupplier({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/supplier/createSupplier", params);
      return data;
    },
    async updateSupplier({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/supplier/updateSupplier", params);
      return data;
    },
    async getSettleMethodList({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/supplier/getBalanceWay", params);
      return data;
    },
    async getSupplierList({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/supplier/querySupplierList", params);
      return data;
    },
    async getSupplierDetail({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/supplier/getSupplierByCode", params);
      return data;
    },
    async updateStatus({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/supplier/batchUpdateStatus", params);
      return data;
    }
  }
};

export default store;
