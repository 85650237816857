import { getTestPort } from "@/utils/common";
const VUE_APP_URL_API = getTestPort();
const store = {
  namespaced: true,
  state: {
    categoryList: []
  },
  getters: {},
  mutations: {},
  actions: {
    async createCategory({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opParts/category/createPartsCategory", params);
      return data;
    },
    async getCategoryList({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opParts/category/queryPartsCategoryList", params);
      return data;
    },
    async deleteCategory({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opParts/category/deletePartsCategory", params);
      return data;
    },
    async updateCategory({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opParts/category/updatePartsCategory", params);
      return data;
    }
  }
};

export default store;
