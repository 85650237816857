import t from "@common/src/i18n/t";
const routerArr = [
  {
    path: "statement/turnover-list",
    name: "statementTurnoverList",
    component: () => import("../views/statement/turnover-list.vue"),
    meta: {
      title: t("v210831.turnover-statement"),
      menuKey: "statement"
    }
  },
  {
    path: "statement/basics-list",
    name: "statementBasicsList",
    component: () => import("../views/statement/basics-list.vue"),
    meta: {
      title: "基础数据报表",
      menuKey: "statement"
    }
  },
  {
    path: "statement/turnover-view",
    name: "statementTurnoverView",
    component: () => import("../views/statement/turnover-view.vue"),
    meta: {
      title: t("v210831.turnover-analysis"),
      menuKey: "statement"
    }
  },
  {
    path: "/statement/report-gross-profit",
    name: "StatementReportGrossProfit",
    component: () => import("../views/statement/report-gross-profit.vue"),
    meta: {
      title: t("finance.gross-analysis"),
      menuKey: "statement"
    }
  },
  {
    path: "/statement/inventory",
    name: "statementInventory",
    component: () => import("../views/statement/inventory-query.vue"),
    meta: {
      title: t("warehouse.inventory-query"),
      menuKey: "inventory"
    }
  },
  {
    path: "statement/customer-list",
    name: "statementCustomerList",
    component: () => import("../views/statement/customer-list.vue"),
    meta: {
      title: t("v210831.customer-information-report"),
      menuKey: "statement"
    }
  },
  {
    path: "statement/customer-view",
    name: "statementCustomerView",
    component: () => import("../views/statement/customer-view.vue"),
    meta: {
      title: t("v210831.customer-analysis"),
      menuKey: "statement"
    }
  },
  {
    path: "statement/market-list",
    name: "statementMarketList",
    component: () => import("../views/statement/market-list.vue"),
    meta: {
      title: t("v210831.tire-sales-report"),
      menuKey: "statement"
    }
  },
  {
    path: "statement/market-view",
    name: "statementMarketView",
    component: () => import("../views/statement/market-view.vue"),
    meta: {
      title: t("v210831.tire-sales-analysis"),
      menuKey: "statement"
    }
  }
];

export default routerArr;
