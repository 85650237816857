const VUE_APP_URL_API = process.env.VUE_APP_URL_API;
const base = {
  namespaced: true,
  state: {
    temporarilyItemCode: localStorage.getItem("se_temporarily_item_code") || "",
    msmBalance: undefined // 短信条数
  },
  getters: {},
  mutations: {
    setTemporarilyItemCode(state, data) {
      state.temporarilyItemCode = data;
      localStorage.setItem("se_temporarily_item_code", data);
    },
    msmBalanceCommit(state, data) {
      state.msmBalance = data;
    }
  },
  actions: {
    async getvehicleTree({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/vehicleModel/queryModelTree", params);
      return data;
    },
    async getAreaList({ rootState, commit }, params = { level: 1 }) {
      const data = await rootState.http(VUE_APP_URL_API + "/area/queryAreaCode", params);
      return data;
    },
    async getDictionaryList({ rootState, commit }, dictionaryCode = "") {
      const data = await rootState.http(VUE_APP_URL_API + "/basic/queryDictionaryList", {
        dataCode: dictionaryCode
      });
      if (data.data && data.data.list) {
        return data.data.list.map(item => {
          return Object.assign(item, {
            label: item.dataDesc,
            value: item.dataValue
          });
        });
      } else {
        return [];
      }
    },
    async getVehicleInfoByVin({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/vehicleModel/getVehicleListByVin", params, {
        errorMessage: false
      });
      return data;
    },
    async getSystemValue({ rootState, commit }, name = "") {
      const data = await rootState.http(VUE_APP_URL_API + "/basic/getDataValue", {
        dataCode: name
      });
      return data;
    },
    async updateSystemValue({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/basic/saveDataValue", params);
      return data;
    },
    async getUploadToken({ rootState, commit }, params = { businessType: "0" }) {
      const data = await rootState.http(VUE_APP_URL_API + "/filecloud/getOssToken", params);
      return data;
    },
    async getNewOrderMessage({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/external/order/message/newOrder", params);
      return data;
    },
    async createTrack({ rootState, commit, dispatch }, pageUrl) {
      const params = {
        pageUrl: pageUrl
      };
      const data = await rootState.http(VUE_APP_URL_API + "/logger/trackAccess", params, { errorMessage: false });
      if (rootState.app.appVersion && rootState.app.appVersion !== data.data) {
        await dispatch("user/loginOut", {}, { root: true });
      }
      commit("app/setAppVersion", data.data, { root: true });
      return data;
    },
    async getProtocolVersion({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/agreement/findLatestAgreement", params);
      return data;
    },
    async queryStoreMessageBalance({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/store/getMessageBalance", params);
      commit("msmBalanceCommit", data.data.smsBalance);
      return data;
    }
  }
};

export default base;
