import router from "../../router/index";
import { getTestPort } from "@/utils/common";
const VUE_APP_URL_API = getTestPort();
const user = {
  namespaced: true,
  state: {
    token: localStorage.getItem("se_authToken") || "",
    userData: JSON.parse(localStorage.getItem("se_userData")) || {},
    menuList: localStorage.getItem("se_menus")?JSON.parse(localStorage.getItem("se_menus")) : [],
    permissionList: JSON.parse(localStorage.getItem("se_permission_list")) || [],
    selectedOrgCode: JSON.parse(localStorage.getItem("se_orgCode")) || ""
  },
  getters: {
    isLogin(state) {
      return state.token && Object.keys(state.userData).length > 0;
    }
  },
  mutations: {
    setToken(state, data) {
      if (data || data === "") {
        state.token = data;
        localStorage.setItem("se_authToken", data);
      }
    },
    setHomeData(state, data) {
      state.homeData = data;
    },
    setUserData(state, data) {
      if (data) {
        state.userData = data;
        localStorage.setItem("se_userData", JSON.stringify(data));
      }
    },
    setOrgCode(state, data) {
      state.selectedOrgCode = data;
      localStorage.setItem("se_orgCode", JSON.stringify(data));
    },
    setMenuList(state, data) {
      if (data.length > 0) {
        state.menuList = data;
        // console.log(data)
        // data[8].children.push({
        //   menuCode: "MC200115",
        //   menuName: "拼团管理",
        //   menuIcon: null,
        //   children: [
        //     {
        //       menuCode: "MC200123",
        //       menuName: "拼团活动",
        //       menuIcon: null,
        //       pageUrl: "/management/groupBuying/groupActivity/list",
        //       children: null
        //     },
        //     {
        //       menuCode: "MC200124",
        //       menuName: "拼团记录",
        //       menuIcon: null,
        //       pageUrl: "/management/groupBuying/groupOrder/list",
        //       children: null
        //     }
        //   ]
        // });
        // const customer = {
        //   menuCode: "MC200099",
        //   menuIcon: "icon_car_user",
        //   menuName: "新车",
        //   pageUrl: null,
        //   children: [
        //     {
        //       menuCode: "MC200199",
        //       menuName: "线索列表",
        //       menuIcon: null,
        //       pageUrl: "/newCarUser/index",
        //       children: null
        //     }
        //   ]
        // };
        // const clue = {
        //   children: null,
        //   menuCode: "MC210001",
        //   menuIcon: "icon-clue",
        //   menuName: "线索",
        //   pageUrl: "/clue/index"
        // };
        // let marketing = {
        //   menuCode: "MC006000",
        //   menuName: "营销",
        //   menuIcon: "icon-marketing",
        //   pageUrl: null,
        //   children: [
        //   {
        //   menuCode: "MC006010",
        //   menuName: "卡设置",
        //   menuIcon: null,
        //   pageUrl: null,
        //   children: [
        //   {
        //   menuCode: "MC006011",
        //   menuName: "卡模板",
        //   menuIcon: null,
        //   pageUrl: "/marketing/card-template",
        //   children: null
        //   },
        //   {
        //   menuCode: "MC006012",
        //   menuName: "卡管理",
        //   menuIcon: null,
        //   pageUrl: "/marketing/card",
        //   children: null
        //   }
        //   ]
        //   },
        //   {
        //   menuCode: "MC006030",
        //   menuName: "优惠券",
        //   menuIcon: null,
        //   pageUrl: null,
        //   children: [
        //   {
        //   menuCode: "MC006031",
        //   menuName: "优惠券",
        //   menuIcon: null,
        //   pageUrl: "/marketing/discount-coupon",
        //   children: null
        //   },
        //   {
        //   menuCode: "MC006032",
        //   menuName: "优惠券发放",
        //   menuIcon: null,
        //   pageUrl: "/marketing/coupon-send",
        //   children: null
        //   }
        //   ]
        //   },
        //   ]
        //   }
        // data.splice(1, 0, customer);
        // console.log(data)
        // data.splice(1, 0, customer, clue, marketing);
        localStorage.setItem("se_menus", JSON.stringify(data));
      } else {
        localStorage.setItem("se_menus", "");
      }
    },
    setPermissionList(state, data) {
      if (data) {
        state.permissionList = data;
        localStorage.setItem("se_permission_list", JSON.stringify(data));
      }
    }
  },
  actions: {
    async login({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opUserAccount/login", params, {
        errorMessage: false
      });
      commit("setToken", data.data.userAuthToken);
      return data;
    },
    async changePassword({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/userAccount/changePassword", params, {
        errorMessage: false
      });
      return data;
    },
    async getUserData({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/userAccount/getUserAccount", params);
      commit("setUserData", data.data);
    },
    async queryUserMenus({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/userAccount/queryUserMenus", params);
      commit("setMenuList", data.data.list);
    },
    async loginOut({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/userAccount/logout", params);
      commit("setUserData", {});
      commit("setMenuList", []);
      commit("setToken", "");
      router.push("/login");
      return data;
    },
    async getMobileCode({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/basic/sendValidationCode", params, {
        errorMessage: false
      });
      return data;
    },
    async resetPassword({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/userAccount/resetPassword", params, {
        errorMessage: false
      });
      return data;
    },
    async setCurrentStore({ rootState, commit }, params = { orgCode: "" }) {
      const data = await rootState.http(VUE_APP_URL_API + "/staff/setCurrentStore", params);
      commit("setting/setServiceMode", null, { root: true });
      commit("user/setOrgCode", params.orgCode, { root: true });
      return data;
    },
    async queryUserResources({ rootState, commit }, params = { orgCode: "" }) {
      const data = await rootState.http(VUE_APP_URL_API + "/userAccount/queryUserResources", params);
      return data;
    }
  }
};

export default user;
