






import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
  inheritAttrs: false
})
export default class AreaSelect extends Vue {
  static componentName = "SbAreaSelect";
  @Prop({ default: false })
  anyLevel: boolean;
  @Prop({ default: () => {} })
  getAreaMethod: any;
  props: any = {
    lazy: true,
    checkStrictly: false,
    label: "areaName",
    value: "areaName",
    lazyLoad: async (node, resolve) => {
      const { level, data: nodeData } = node;
      if (level === 0) {
        const data = await this.getAreaMethod();
        resolve(data.data.list);
      } else {
        const data = await this.getAreaMethod({ parentAreaCode: nodeData.areaCode });
        let list = data.data.list;
        if (level === 2) {
          list = data.data.list.map(item => {
            item.leaf = true;
            return item;
          });
        }
        resolve(list);
      }
    }
  };
  created() {
    this.props.checkStrictly = this.anyLevel;
  }
  getLabels() {
    let str = (this.$refs["sb-area-select"] as any).querySelector(
      ".sb-area-select .el-cascader .el-input__inner"
    ).value;
    console.log(str);
    return str.split(" / ");
  }
}
