const app = {
  namespaced: true,
  state: {
    sideNavIndex: 0,
    orderPushState: false,
    lang: localStorage.getItem("se_lang") || navigator.language || "zh-CN",
    pbpx: 0,
    btnLoading: false
  },
  getters: {},
  mutations: {
    setSideNavIndex(state, data) {
      state.sideNavIndex = data;
    },
    setOrderPushState(state, data) {
      state.orderPushState = data;
    },
    setHomeData(state, data) {
      state.homeData = data;
    },
    setPBPX(state, data) {
      state.pbpx = data;
    },
    btnStartLoading(state) {
      state.btnLoading = true;
    },
    btnStopLoading(state) {
      state.btnLoading = false;
    }
  },
  actions: {}
};

export default app;
