import { getTestPort } from "@/utils/common";
const VUE_APP_URL_API = getTestPort();
const store = {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // 任务列表
    async userTaskList({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/user/task/list", params);
      return data;
    },
    // 任务排序上移
    async userTaskSortUp({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + `/op/user/task/${params.taskNo}/sortUp`, params);
      return data;
    },
    // 任务排序下移
    async userTaskSortDown({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + `/op/user/task/${params.taskNo}/sortDown`, params);
      return data;
    },
    // 任务排序置顶
    async userTaskSortTop({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + `/op/user/task/${params.taskNo}/sortTop`, params);
      return data;
    },
    // 任务排序置顶
    async userTaskPublish({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + `/op/user/task/${params.taskNo}/publish`, params);
      return data;
    },
    // 任务排序置顶
    async userTaskPublishDown({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + `/op/user/task/${params.taskNo}/publishDown`, params);
      return data;
    },
    // 任务保存
    async userTaskSave({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/user/task/save", params);
      return data;
    },
    // 任务详情
    async userTaskDetail({ rootState, commit }, params) {
      params.method = "get";
      const data = await rootState.http(VUE_APP_URL_API + `/op/user/task/${params.taskNo}/detail`, params);
      return data;
    }
  }
};

export default store;
