import { getTestPort } from "@/utils/common";

const VUE_APP_FACTORY_API = process.env.VUE_APP_FACTORY_API;

const VUE_APP_URL_API = getTestPort();
const store = {
  namespaced: true,
  state: {
    currentTabType: "all"
  },
  getters: {},
  mutations: {
    setCurrentTabType(state, data) {
      state.currentTabType = data;
    }
  },
  actions: {
    async getCustomerList({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/customer/queryCustomerList", params);
      return data;
    },
    async saveCustomer({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/customer/saveCustomer", params);
      return data;
    },
    async getClueList({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_FACTORY_API + "/saas/clue/getClueList", params);
      return data;
    },
    // 获取线索详情
    async getClueDetail({ rootState }, params = {}) {
      const data = await rootState.http(`${VUE_APP_FACTORY_API}/saas/clue/getClueDetail`, params);
      return data;
    },
    // 获取线索列表
    async getAllItems({ rootState }, params = {}) {
      const data = await rootState.http(`${VUE_APP_FACTORY_API}/saas/clue/getAllItems`, params);
      return data;
    },
    // 获取线索沟通记录
    async getCLueContactReocrds({ rootState }, params = {}) {
      const data = await rootState.http(`${VUE_APP_FACTORY_API}/saas/clue/getCLueContactReocrds`, params);
      return data;
    },
    async getCustomerDetail({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/customer/getCustomer", params);
      return data;
    },
    async queryInsureList({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/insurer/queryInsurerList", params);
      return data;
    },
    async getCustomerExpensesRecord({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opOrder/queryCustomerExpensesRecord", params);
      return data;
    },
    async batchUpdateVehicleStatus({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/customer/batchUpdateVehicleStatus", params);
      return data;
    },
    async getNewCustomerList({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/user/list", params);
      return data;
    },
    async getNewCustomerDetail({ rootState, commit }, params:any) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/user/"+params+"/detail", {});
      return data;
    },
    async getUserExpensesRecord({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opOrder/queryUserExpensesRecord", params);
      return data;
    },

    async getBillList({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/inner/activity/bill/list", params);
      return data;
    },
    async getCrowdUserList({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/summary/pageCrowdUserResult", params);
      return data;
    },
  }
};

export default store;
