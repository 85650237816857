import { getTestPort } from "@/utils/common";
const VUE_APP_URL_API = getTestPort();
const vehicleCheck = {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        async getCheckOrderList({ rootState }, params = {}) {
            const data = await rootState.http(VUE_APP_URL_API + "/opCheckOrder/queryList", params);
            return data;
        },
        async getCheckOrderDetail({ rootState }, params = {}) {
            const data = await rootState.http(VUE_APP_URL_API + "/opCheckOrder/getCheckOrder", params);
            return data;
        }
    }
};

export default vehicleCheck;
