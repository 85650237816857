import { getTestPort } from "@/utils/common";
const VUE_APP_URL_API = getTestPort();
const plusTemplate = {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // 获取plus模板分页列表
    async getPlusTemplatePageList({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/plus/card/list", params);
      return data;
    },
    // 获取plus模板详情
    async getPlusTemplateDetail({ rootState, commit }, params) {
      params.method = "get";
      const data = await rootState.http(VUE_APP_URL_API + "/op/plus/card/" + params.plusTemplateCode +"/detail", params);
      return data;
    },
    // plus模板上架
    async plusTemplatePublish({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/plus/card/" + params.plusTemplateCode +"/publish", params);
      return data;
    },
    // plus模板下架
    async plusTemplatePublishDown({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/plus/card/" + params.plusTemplateCode +"/publishDown", params);
      return data;
    },
    // plus模板保存
    async plusTemplateSave({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/plus/card/save", params);
      return data;
    }
  }
};

export default plusTemplate;
