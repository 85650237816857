import Vue from "vue";
import VueI18n, { LocaleMessages } from "vue-i18n";
import enLocale from "element-ui/lib/locale/lang/en";
import zhCNLocale from "element-ui/lib/locale/lang/zh-CN";
import sbEnLocale from "@/speedbiz-ui/src/locale/lang/en";
import sbZhCNLocale from "@/speedbiz-ui/src/locale/lang/zh-CN";
import { getCountry } from "@common/src/utils/common";
Vue.use(VueI18n);
const elementLocale = {
  en: enLocale,
  "zh-CN": zhCNLocale
};
const speedbizLocale = {
  en: sbEnLocale,
  "zh-CN": sbZhCNLocale
};
function loadLocaleMessages(): LocaleMessages {
  const locales = require.context("./locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages: LocaleMessages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = { ...locales(key), ...elementLocale[locale], ...speedbizLocale[locale] };
    }
  });
  return { ...messages };
}

// Create VueI18n instance with options
const countryLangMap = {
  china: "zh-CN",
  my: "en"
};
const lang = localStorage.getItem("se_lang") || countryLangMap[getCountry()] || "zh-CN";

console.log(process.env.lang);
const i18n = new VueI18n({
  locale: lang, // set locale
  messages: loadLocaleMessages() // set locale messages
});
window._I18N = i18n;
localStorage.setItem("se_lang", lang);
export default i18n;
