import commonApp from "../store/modules/app";
export default {
  install: function(Vue) {
    Vue.prototype.$localMark = {
      lang: process.env.lang, // zh-CN
      countryCode: process.env.countryCode, // +86
      // currency: process.env.currency, // 元
      // currencyBrackets: `(${process.env.currency})`, // (元)
      currencySymbol: process.env.currencySymbol, // ￥
      get currency() {
        return commonApp.state.lang === "zh-CN" ? process.env.currency : "-";
      },
      get currencyBrackets() {
        return commonApp.state.lang === "zh-CN" ? `(${process.env.currency})` : "";
      }
    };
  }
};
