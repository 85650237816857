import { getTestPort } from "@/utils/common";
const VUE_APP_URL_API = getTestPort();
const store = {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async pageQueryCategory({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/opServiceCategory/pageQuery", params);
      return data;
    },
    async setServiceCategoryIcon({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/opServiceCategory/changeIcon", params);
      return data;
    },
    async createServiceCategory({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/opServiceCategory/add", params);
      return data;
    },
    async updateServiceCategory({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/opServiceCategory/update", params);
      return data;
    },
    async serviceCategoryDetail({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + `/opServiceCategory/${params.categoryCode}/detail`, params);
      return data;
    },
    async serviceCategoryEnable({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + `/opServiceCategory/${params.categoryCode}/enable`, params);
      return data;
    },
    async serviceCategoryDisable({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + `/opServiceCategory/${params.categoryCode}/disable`, params);
      return data;
    },
    async serviceCategorySortUp({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + `/opServiceCategory/${params.categoryCode}/sortUp`, params);
      return data;
    },
    async serviceCategorySortDown({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + `/opServiceCategory/${params.categoryCode}/sortDown`, params);
      return data;
    }
  }
};

export default store;
