/**
 * 除了0 所有能转换为false的都用“-”代替
 * @param {number} val 目标值
 * @param {number} showValue  val格式化后的值
 * @return {string}
 */
export function emptyToLine(val, showValue?) {
  return val || val === 0 ? (showValue ? showValue : val) : "-";
}

/**
 * 生成uuid
 * @param {number} len 长度
 * @param {number} radix 基数(范围)
 * @return {string}
 */
export function getUuid(len, radix?) {
  const chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split("");
  const uuid = [];
  let i;
  radix = radix || chars.length;
  if (len) {
    for (i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)];
  } else {
    let r;
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = "-";
    uuid[14] = "4";
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | (Math.random() * 16);
        uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r];
      }
    }
  }
  return uuid.join("");
}
