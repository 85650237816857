import router from "../../router/index";
import { getTestPort } from "@/utils/common";
const VUE_APP_URL_API = getTestPort();
const staff = {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {
    setToken(state, data) {
      state.token = data;
    }
  },
  actions: {
    async getStaffList({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/staff/queryStaffAccountList", params);
      return data;
    },
    async createStaff({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/staff/createStaffAccount", params);
      return data;
    },
    async updateStaff({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/staff/updateStaffAccount", params);
      return data;
    },
    async getStaffDetail({ rootState, commit }, params = {}) {
      console.log(arguments);
      const data = await rootState.http(VUE_APP_URL_API + "/staff/getStaffAccount", params, {
        errorMessage: false
      });
      return data;
    },
    async getTechnicianStaffList({ rootState, commit }, params = {}) {
      console.log(arguments);
      const data = await rootState.http(VUE_APP_URL_API + "/staff/queryTechnicianList", params, {
        errorMessage: false
      });
      return data;
    },
    async saveOpAccount({ rootState, commit }, params = {}) {
      console.log(arguments);
      const data = await rootState.http(VUE_APP_URL_API + "/opUserAccount/save", params);
      return data;
    },
    async queryAccountDetail({ rootState, commit }, params = {}) {
      console.log(arguments);
      const data = await rootState.http(VUE_APP_URL_API + "/opUserAccount/getUserAccount", params);
      return data;
    },
    async queryOpAccountList({ rootState, commit }, params = {}) {
      console.log(arguments);
      const data = await rootState.http(VUE_APP_URL_API + "/opUserAccount/queryUserAccount", params);
      return data;
    }
  }
};

export default staff;
