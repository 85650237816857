import { getTestPort } from "@/utils/common";
const VUE_APP_URL_API = getTestPort();
const app = {
  namespaced: true,
  state: {
    questionTypeList: []
  },
  getters: {},
  mutations: {
    setQuestionTypeList(state, data) {
      state.questionTypeList = data;
    }
  },
  actions: {
    async getDefaultSerialNumber({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/learn/content/getDefaultSerialNumber", params);
      return data;
    },
    async updateCertificationLevel({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/certificationLevel/updateCertificationLevel", params);
      return data;
    },
    async createCertificationLevel({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/certificationLevel/createCertificationLevel", params);
      return data;
    },
    async updateLevelStatus({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/certificationLevel/updateLevelStatus", params);
      return data;
    },
    async changeLevelOrder({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/certificationLevel/changeLevelOrder", params);
      return data;
    },
    // 认证等级
    async queryLevelList({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/certificationLevel/queryLevelList", params);
      return data;
    },
    // 认证等级-启用的
    async queryEnableLevelList({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/certificationLevel/queryEnabledLevelList", params);
      return data;
    },
    // 学习分类
    // 查询学习分类列表
    async queryLearnClassList({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/learnContentClass/queryLearnClassList", params);
      return data;
    },
    // 排序学习分类
    async changeLearnClassOrder({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/learnContentClass/changeLearnClassOrder", params);
      return data;
    },
    // 新增学习分类
    async createLearnClass({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/learnContentClass/createLearnClass", params);
      return data;
    },
    // 删除学习分类
    async delLearnClass({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/learnContentClass/delLearnClass", params);
      return data;
    },
    // 修改学习分类
    async updateLearnClassName({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/learnContentClass/updateLearnClassName", params);
      return data;
    },
    // 学习
    // 查询学习列表
    async getLearnContentSettingPage({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/learn/content/getLearnContentSettingPage", params);
      return data;
    },
    // 查询学习列表详情  获取学习内容设置明细
    async getLearnContentSettingDetail({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/learn/content/getLearnContentSettingDetail", params);
      return data;
    },
    // 查询学习列表详情  获取学习内容文本明细
    async getLearnContentTextDetail({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/learn/content/getLearnContentTextDetail", params);
      return data;
    },
    // 新增学习
    async createLearnContent({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/learn/content/createLearnContent", params);
      return data;
    },
    // 编辑学习
    async updateLearnContent({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/learn/content/updateLearnContent", params);
      return data;
    },
    // 发布学习
    async releaseLearnContent({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/learn/content/releaseLearnContent", params);
      return data;
    },
    // 下架学习
    async offShelfLearnContent({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/learn/content/offShelfLearnContent", params);
      return data;
    },
    // 发布人列表
    async queryUserAccountList({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/userAccount/queryUserAccountList", params);
      return data;
    },
    // 互动分类
    // 查询 分类列表
    async queryInvitationClassList({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/learn/invitationClass/queryInvitationClassList", params);
      return data;
    },
    // 创建 互动分类
    async createInvitationClass({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/learn/invitationClass/createInvitationClass", params);
      return data;
    },
    // 删除 互动分类
    async deleteInvitationClass({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/learn/invitationClass/deleteInvitationClass", params);
      return data;
    },
    // 排序 互动分类
    async changeInvitationClassSort({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/learn/invitationClass/changeInvitationClassSort", params);
      return data;
    },
    // 学习互动
    // 帖子列表
    async searchLearnInvitationPage({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/learn/invitation/searchLearnInvitationPage", params);
      return data;
    },
    // 帖子明细 详情
    async getLearnInvitationDetail({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/learn/invitation/getLearnInvitationDetail", params);
      return data;
    },
    // 帖子评论列表
    async searchInvitationCommentPage({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/learn/invitation/searchInvitationCommentPage", params);
      return data;
    },
    // 点赞帖子
    async thumbsUpLearnInvitation({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/learn/invitation/thumbsUpLearnInvitation", params);
      return data;
    },
    // 我的帖子列表
    async myLearnInvitationPage({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/learn/invitation/myLearnInvitationPage", params);
      return data;
    },
    // 我的评论列表
    async myInvitationCommentPage({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/learn/invitation/myInvitationCommentPage", params);
      return data;
    },
    // 新建帖子
    async createLearnInvitation({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/learn/invitation/createLearnInvitation", params);
      return data;
    },
    // 新建评论
    async createInvitationComment({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/learn/invitation/createInvitationComment", params);
      return data;
    },
    // 删除帖子
    async deleteLearnInvitation({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/learn/invitation/deleteLearnInvitation", params);
      return data;
    },
    // 删除评论
    async deleteInvitationComment({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/learn/invitation/deleteInvitationComment", params);
      return data;
    },
    async queryExamPaperList({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/examPaperTemp/queryList", params);
      return data;
    },
    async queryExamSignUserList({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/opLearn/querySignUpUser", params);
      return data;
    },
    async revokeExam({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/examPaperTemp/revoke", params);
      return data;
    },
    async saveExamPaper({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/examPaperTemp/saveExamPaperTemp", params);
      return data;
    },
    async queryExamPaperDetail({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/examPaperTemp/getExamPaperTemp", params);
      return data;
    },
    async queryscoringList({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/opLearn/queryOpAnswerSheetPage", params);
      return data;
    },
    async queryAnswerSheetDetail({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/opLearn/queryOpAsDetailByCode", params);
      return data;
    },
    async submitScoring({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/opLearn/submitOpScore", params);
      return data;
    }
  }
};

export default app;
