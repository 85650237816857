import { Message } from "element-ui";

export default {
  install: function(Vue) {
    Vue.prototype.$message = {
      success(msg) {
        Message.success({ message: msg, offset: 11, duration: 2000 });
      },
      error(msg) {
        Message.error({ message: msg, offset: 11, duration: 2000 });
      },
      warning(msg) {
        Message.warning({ message: msg, offset: 11, duration: 2000 });
      },
      info(msg) {
        Message.info({ message: msg, offset: 11, duration: 2000 });
      }
    };
  }
};
