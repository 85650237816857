import { getTestPort } from "@/utils/common";
const VUE_APP_URL_API = getTestPort();
const store = {
  namespaced: true,
  state: {
    storeList: []
  },
  getters: {},
  mutations: {
    setStoreList(state, data) {
      state.storeList = data;
    }
  },
  actions: {
    async getStoreList({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/store/queryStoreList", params);
      commit("setStoreList", data.data.list || []);
      return data;
    }
  }
};

export default store;
