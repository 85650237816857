import { getTestPort } from "@/utils/common";
const VUE_APP_URL_API = getTestPort();
const detecationItem = {
    namespaced: true,
    state: {
        serviceMode: null
    },
    getters: {},
    mutations: {
        setServiceMode(state, data) {
            state.serviceMode = data ? data : null;
        }
    },
    actions: {
        async getDetecationItemList({ rootState, commit }, params) {
            params.method = 'get';
            const data = await rootState.http(VUE_APP_URL_API + "/athenaApi/op/detecationItem/pageList", params);
            return data;
        },
        getDetecationItemDetail({ rootState, commit }, params) {
            params.method = 'get';
            const data = rootState.http(VUE_APP_URL_API + "/athenaApi/op/detecationItem/"+ params.projectCode +"/detail", params);
            return data;
        },
        async saveDetecationItem({ rootState, commit }, params) {
            const data = await rootState.http(VUE_APP_URL_API + "/athenaApi/op/detecationItem/save", params);
            return data;
        },
        async getAllItemCodeNamList({ rootState, commit }, params) {
            params.method = 'get';
            const data = await rootState.http(VUE_APP_URL_API + "/athenaApi/op/detecationItem/codeNames", params);
            return data;
        },
        async detecationItemEnable({ rootState, commit }, params) {
            const data = await rootState.http(VUE_APP_URL_API + "/athenaApi/op/detecationItem/"+ params.projectCode +"/enable", params)
            return data;
        },
        async detecationItemDisable({ rootState, commit }, params) {
            const data = await rootState.http(VUE_APP_URL_API + "/athenaApi/op/detecationItem/"+ params.projectCode +"/disable", params)
            return data;
        },

        async maintainCalendarProjectPage({ rootState, commit }, params) {
            const data = await rootState.http(VUE_APP_URL_API + "/athenaApi/op/maintainCalendar/project/page", params)
            return data;
        },

        async maintainCalendarUpdateProjectState({ rootState, commit }, params) {
            const data = await rootState.http(VUE_APP_URL_API + "/athenaApi/op/maintainCalendar/project/updateState", params)
            return data;
        },

        async maintainCalendarSaveOrUpdateProject({ rootState, commit }, params) {
            const data = await rootState.http(VUE_APP_URL_API + "/athenaApi/op/maintainCalendar/project/saveOrUpdate", params)
            return data;
        },

        async maintainCalendarProjectInfo({ rootState, commit }, params) {
            const data = await rootState.http(VUE_APP_URL_API + "/athenaApi/op/maintainCalendar/project/info", params)
            return data;
        },





    }
};

export default detecationItem;
