









import { Component, Prop, Vue } from "vue-property-decorator";
@Component({})
export default class SbHeadline extends Vue {
  static componentName: string = "SbHeadline";
  @Prop({ default: "门店管理系统" })
  title: string;
  @Prop({ default: true })
  showBottomBorder: boolean;
}
