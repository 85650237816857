import userStore from "@/store/modules/user";
export default {
  install: function(Vue) {
    Vue.directive("permit", {
      inserted: function(el, binding, vnode) {
        if (!userStore.state.permissionList.includes(binding.value)) {
          el.parentNode && el.parentNode.removeChild(el);
        }
      },
      update: function(el, binding, vnode) {
        if (!userStore.state.permissionList.includes(binding.value)) {
          el.parentNode && el.parentNode.removeChild(el);
          setTimeout(() => {
            el.parentNode && el.parentNode.removeChild(el);
          }, 0);
        }
      }
    });
  }
};
