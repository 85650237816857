import { getTestPort } from "@/utils/common";
const VUE_APP_URL_API = getTestPort();
const plusCustomer = {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // 获取plus购买记录分页列表
    async getPlusCustomerPageList({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/plus/customer/list", params);
      return data;
    }
  }
};

export default plusCustomer;
