import { getTestPort } from "@/utils/common";
const VUE_APP_URL_API = getTestPort();
const app = {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getHomeOrderData({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/home/getHomeOrderInfo", params);
      return data;
    },
    async getHomeCustomerData({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/home/getHomeCustomerInfo", params);
      return data;
    },
    async getOrderStatistic({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/order/orderStatistic", params);
      return data;
    }
  }
};

export default app;
