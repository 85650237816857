import i18n from "../../i18n/i18n";
const app = {
  namespaced: true,
  state: {
    sideNavIndex: 0,
    orderPushState: false,
    lang: localStorage.getItem("se_lang") || navigator.language || "zh-CN",
    btnLoading: false,
    appVersion: localStorage.getItem("se_app_version") || "",
    protocolVersion: localStorage.getItem("se_protocol_version") || "",
    pbpx: 0, //main区块 padding bottom 的值
    isTmall: localStorage.getItem("se_isTmall") || "",
    appType: localStorage.getItem("se_appType") || "",
    agreementProtocolFlag: Number(localStorage.getItem("se_agreement_protocol") || "0") || 0,
    isViewAlive: true
  },
  getters: {
    isAgreementProtocol(state) {
      return !!state.agreementProtocolFlag;
    }
  },
  mutations: {
    setIsViewAlive(state, bool) {
      state.isViewAlive = bool;
    },
    btnStartLoading(state) {
      state.btnLoading = true;
    },
    btnStopLoading(state) {
      state.btnLoading = false;
    },
    setSideNavIndex(state, data) {
      state.sideNavIndex = data;
    },
    setOrderPushState(state, data) {
      state.orderPushState = data;
    },
    setHomeData(state, data) {
      state.homeData = data;
    },
    setLang(state, data) {
      localStorage.setItem("se_lang", data);
      state.lang = data;
      i18n.locale = data;
    },
    setPBPX(state, data) {
      state.pbpx = data;
    },
    setAppVersionInfo(state, data) {
      state.isTmall = data.tmall || "";
      state.appType = data.appType || "";
      localStorage.setItem("se_isTmall", data.tmall || "");
      localStorage.setItem("se_appType", data.appType || "");
    },
    setAppVersion(state, data) {
      if (!data) {
        localStorage.setItem("se_app_version", "");
        state.appVersion = "";
      } else {
        localStorage.setItem("se_app_version", data);
        state.appVersion = data;
      }
    },
    setProtocolVersion(state, data) {
      if (!data) {
        localStorage.setItem("se_protocol_version", "");
        state.protocolVersion = "";
      } else {
        localStorage.setItem("se_protocol_version", data);
        state.protocolVersion = data;
      }
    },
    setAgreementProtocolFlag(state, data) {
      if (data) {
        data = 1;
      } else {
        data = 0;
      }
      state.agreementProtocolFlag = data;
      localStorage.setItem("se_agreement_protocol", data);
    }
  },
  actions: {}
};

export default app;
