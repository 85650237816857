
import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
const app = namespace("app");
const common_app = namespace("common_app");
@Component({})
export default class App extends Vue {
  @common_app.State isViewAlive;
  @app.Mutation setPBPX;
  created() {
    console.log(this.$localMark);
  }
  @Watch("$route")
  watchRoute(val) {
    this.setPBPX(0);
  }
}
