















import { Component, Prop, Vue } from "vue-property-decorator";
import Editor from "@tinymce/tinymce-vue";
import { t } from "../../src/locale";

@Component({
  components: { Editor }
})
export default class SbEditor extends Vue {
  static componentName = "SbEditor";
  editor: any = {};
  showFileUpload: boolean = false;
  showEditor: boolean = false;
  fileUrl: string = "";
  fileList: any = [];
  @Prop({ type: Boolean, default: false })
  hideVideoBtn: boolean;
  @Prop({ type: Function })
  uploadHandler: any;
  @Prop({ type: Function, default: () => {} })
  getTokenMethod: any;
  @Prop({ type: String, default: "zh_CN" })
  language: string;
  handlePreview() {}
  handleExceed() {}
  handleVideoSuccess() {
    if (!this.fileUrl) {
      this.$message.warning(t("sb.please-upload-video-file"));
      return;
    }
    this.editor.insertContent(
      `<p>
          <span class="mce-preview-object mce-object-video" contenteditable="false" data-mce-object="video" data-mce-p-allowfullscreen="allowfullscreen" data-mce-p-frameborder="no" data-mce-p-scrolling="no" data-mce-p-src=${this.fileUrl} data-mce-html="%20">
            <video src=${this.fileUrl} width="100%" controls="controls"></video>
          </span>
      </p>`
    );
    this.$refs.fileupload["handleDeleteImg"](0);
    this.fileUrl = "";
    this.showFileUpload = false;
  }
  editConfig: any = {
    language: "", //语言
    readonly: false,
    width: "100%",
    height: 350,
    body_class: "",
    menubar: "file edit insert view format table",
    object_resizing: false,
    toolbar: [
      "undo redo preview bold italic underline fontselect fontsizeselect forecolor backcolor",
      "bullist numlist alignleft aligncenter alignright outdent indent table emoticons link uploadImg image uploadVideo code fullscreen"
    ],
    plugins: [
      "advlist anchor autolink autosave code codesample directionality emoticons fullscreen preview hr image imagetools importcss link lists  nonbreaking noneditable pagebreak paste  print save searchreplace tabfocus table visualblocks visualchars wordcount"
    ],
    end_container_on_empty_block: true,
    powerpaste_word_import: "clean",
    code_dialog_height: 450,
    code_dialog_width: 1000,
    plugin_preview_height: 450,
    plugin_preview_width: 320,
    advlist_number_styles: "default",
    default_link_target: "_blank",
    link_title: false,
    nonbreaking_force_tab: true,
    branding: false,
    convert_fonts_to_spans: false,
    valid_elements: "*[*]",
    entity_encoding: "raw",
    relative_urls: false,
    convert_urls: false,
    schema: "html5",
    mode: "specific_textareas",
    image_default_styles: {
      width: "800px"
    },
    forced_root_block: "",
    extended_valid_elements: "*[*],script[charset|defer|language|src|type],style",
    custom_elements: "*[*],script[charset|defer|language|src|type],style",
    valid_children: "+body[style],+body[script]",
    verify_html: false,
    media_strict: false,
    images_upload_handler: (blobInfo, success, failure) => {
      this.uploadHandler({ blobInfo, success, failure });
    }
  };
  created() {
    const _this = this;
    this.editConfig.language = this.language;
    if (!this.hideVideoBtn) {
      this.editConfig.setup = function (editor) {
        editor.ui.registry.addButton("uploadVideo", {
          text: t("sb.upload-video"),
          onAction: (button: { isDisabled: () => boolean; setDisabled: (p: boolean) => void }) => {
            _this.showFileUpload = true;
          }
        });
      };
    }
    this.editConfig.init_instance_callback = editor => {
      _this.editor = editor;
    };
    this.showEditor = true;
  }
}
