import Vue from "vue";
import Vuex from "vuex";
import getters from "./getters";
import { httpServer as http } from "../api/axios";

Vue.use(Vuex);

const getModules = (group = [], isCommon = false) => {
  const result = {};
  group.forEach(item => {
    let key = item.replace(/(^\.\/)|(\.ts)/g, "");
    if (isCommon) {
      result["common_" + key] = commonFile(item).default || file(item);
    } else {
      result[key] = file(item).default || file(item);
    }
  });
  return result;
};
const file = require.context("./modules", false, /\.ts$/);
const commonFile = require.context("@common/src/store/modules", false, /\.ts$/);
console.log(commonFile);
const modules = getModules(file.keys());
const commonModules = getModules(commonFile.keys(), true);
const allModules = Object.assign({}, modules, commonModules);
export default new Vuex.Store({
  state: {
    http: http
  },
  getters,
  modules: allModules
});
