/**
 * 千位分割
 * @param {number | string} num
 * @return {string}
 */
export function splitThousands(num) {
  if (!num && num !== 0) {
    return num;
  }
  const flag = parseFloat(num) < 0 ? "-" : "";
  let numStr = String(num);
  const end = numStr.split(".")[1] ? "." + numStr.split(".")[1] : "";
  const arr = [...String(Math.abs(parseInt(num)))];
  let result = "";
  while (arr.length > 3) {
    result = "," + arr.splice(-3).join("") + result;
  }
  return flag + arr.join("") + result + end;
}

/**
 * 除了0 所有能转换为false的都用“-”代替
 * @param {number} val 目标值
 * @param {number} showValue  val格式化后的值
 * @return {string}
 */
export function emptyToLine(val, showValue?) {
  return val || val === 0 ? (showValue ? showValue : val) : "--";
}

export function timeFormat(val, showValue?) {
  return val || val === 0 ? (showValue ?  "": val.substr(0,2) + ":" + val.substr(2) ) : "--";
}


export function booleanFormat(val, showValue?) {
  return val !=null && val ? "是" : "否";
}
export function genderFormat(val, showValue?) {
  if(val == null){
    return '未知'
  }
  return val == 1 ? "男" : val == 2 ? "女" : "未知";
}


export function enterWeComFormat(val, showValue?) {
  return val !=null && val ? "已添加" : "未添加";
}

export function downloadAppFormat(val, showValue?) {
  return val !=null && val ? "已下载" : "未下载";
}


export function followOfficialAccountFormat(val, showValue?) {
  return val !=null && val ? "已关注" : "未关注";
}


/**
 * 生成uuid
 * @param {number} len 长度
 * @param {number} radix 基数(范围)
 * @return {string}
 */
export function getUuid(len, radix?) {
  let chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split("");
  let uuid = [],
    i;
  radix = radix || chars.length;
  if (len) {
    for (i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)];
  } else {
    let r;
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = "-";
    uuid[14] = "4";
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | (Math.random() * 16);
        uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r];
      }
    }
  }
  return uuid.join("");
}

/**
 * 8位日期格式化 20200508 => 2020-05-08
 * @param {number} date 日期
 * @return {string}
 */
export function numDateToDate(date) {
  if (!date) {
    return "-";
  }
  return date.substr(0, 4) + "-" + date.substr(4, 2) + "-" + date.substr(6, 2);
}

// 获取当月默认日期-日期格式化
export function formatDate(date) {
  const today = new Date();
  const startDate = new Date(today.getFullYear(), today.getMonth(), 1); // 当月的第一天
  const endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0); // 当月的最后一天
  let startDay = startDate.getDate().toString();
  let startMonth = (startDate.getMonth() + 1).toString(); // 月份是从0开始的
  let endDay = endDate.getDate().toString();
  let endMonth = (endDate.getMonth() + 1).toString(); // 月份是从0开始的
  startDay = startDay.length == 2 ? startDay : "0" + startDay;
  startMonth = startMonth.length == 2 ? startMonth : "0" + startMonth;
  endDay = endDay.length == 2 ? endDay : "0" + endDay;
  endMonth = endMonth.length == 2 ? endMonth : "0" + endMonth;
  return {
    date1: [
      startDate.getFullYear() + "-" + startMonth + "-" + startDay,
      endDate.getFullYear() + "-" + endMonth + "-" + endDay
    ],
    date2: [
      startDate.getFullYear() + "" + startMonth + "" + startDay,
      endDate.getFullYear() + "" + endMonth + "" + endDay
    ],
    month1: [startDate.getFullYear() + "-" + startMonth, endDate.getFullYear() + "-" + endMonth],
    month2: [startDate.getFullYear() + "" + startMonth, endDate.getFullYear() + "" + endMonth]
  }
}

export function dateFormat(date) {
  var year = date.getFullYear();
  var month = addZero(date.getMonth() + 1);
  var day = addZero(date.getDate());
  return year + '-' + month + '-' + day;
}

function addZero(num) {
  return num < 10 ? '0' + num : num;
}

export function addOneYearToDate(date) {
  var newDate = new Date(date);
  newDate.setFullYear(newDate.getFullYear() + 1);
  return newDate;
}

/**
 * 获取图片的高度和宽度
 * @param {Element} imgEl
 * @return {Array} [width, height]
 */
export function getImageSize(imgEl) {
  let width: number = 0,
    height: number = 0;
  height = imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height;
  width = imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width;
  return [width, height];
}

// 根据url获取文件类型
export const getMediaType = url => {
  const imageFormat = ["png", "jpg", "jpge", "ico", "bmp", "gif", "tif", "pcx", "tga"];
  const videoFormat = ["mp4", "avi", "mp4", "avi", "3gp", "rmvb", "wav", "mpg", "mkv", "vob", "mov", "flv", "swf"];
  let arr = url.split(".");
  let type = arr[arr.length - 1];
  if (imageFormat.includes(type.toLowerCase())) {
    return "image";
  } else if (videoFormat.includes(type.toLowerCase())) {
    return "video";
  } else {
    return new Error("not image or video file");
  }
};

// 获取文件类型后缀
export const getFileUrlSuffix = url => {
  try {
    let temArr = url.split(".");
    return temArr[temArr.length - 1].toLowerCase();
  } catch (e) {
    return null;
  }
};

// editorAllowImage

export const isEditorAllowImage = url => {
  return getFileUrlSuffix(url) === "jpg" || getFileUrlSuffix(url) === "png" || getFileUrlSuffix(url) === "jpge";
};

/**
 *根据域名获取测试接口环境
 * @returns port
 */
export function getTestPort() {
  const host = window.location.host;
  if (host.indexOf("operation-dev") > -1) {
    return "https://saas-operation-api.test.haochezhu.club";
  } else {
    return process.env.VUE_APP_URL_API;
  }
}
