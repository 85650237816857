import axios from "axios";
import { Message } from "element-ui";
import userStore from "../store/modules/user";
import commonAppStore from "@common/src/store/modules/app";
import router from "../router";

// let api_url = "";
// let h5_site_url = "";
// switch (window.location.host) {
//   case "operation.test.haochezhu.club":
//     api_url = "https://operation-api.test.haochezhu.club";
//     h5_site_url = "https://operation.test.haochezhu.club";
//     break;
//   case "operation.haochezhu.club":
//     api_url = "https://operation-api.haochezhu.club";
//     h5_site_url = "https://operation.haochezhu.club";
//     break;
//   default:
//     api_url = `${window.location.origin}/api`;
//     h5_site_url = "https://operation.test.haochezhu.club";
//     break;
// }
// process.env.VUE_APP_URL_API = api_url;
// process.env.VUE_APP_H5_SITE = h5_site_url;
// 创建axios实例
const service = axios.create({
  // baseURL: api_url, // api的base_url
  timeout: 60000 // 请求超时时间,
});

// request拦截器
service.interceptors.request.use(
  config => {
    config.headers["userAuthToken"] = userStore.state.token;
    config.headers["userLanguage"] = commonAppStore.state.lang === "en" ? "en-US" : "zh-CN";
    config.headers["clientType"] = "WEB";
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

// respone拦截器
service.interceptors.response.use(
  response => {
    /**
     * code为非0是抛错 结合业务进行修改
     */
    const res = response.data;
    if (res.code !== "0" && res.code != 10000) {
      if (response.config.options.errorMessage) {
        Message.error({
          message: res.message || res.msg,
          offset: 11,
          duration: 2000
        });
      }
      if (
        res.code === "speedbiz.error-user-auth-token" ||
        res.code === "speedbiz.empty-user-auth-token" ||
        res.code === 10003
      ) {
        router.push("/login");
      }
      return Promise.reject(res);
    }
    return res;
  },
  error => {
    // 错误处理

    try {
      switch (error.response.status) {
        case 500:
          error.message = "服务器异常"; // 明确返回500
          break;
        case 404:
          error.message = "服务器地址404"; // 明确返回404
          break;
        default:
          error.message = "发生未知错误"; // 其他错误code
      }
    } catch (e) {
      error.message = "网络拥堵，请稍后重试！"; // 超时 获取不到status
    }
    console.log("error：" + JSON.stringify(error.message));
    if (error.config.options.errorMessage) {
      Message.error({
        message: error.message,
        offset: 11,
        duration: 2000
      });
    }
    return Promise.reject(error);
  }
);

const verifySign = function(param) {
  const constParams = {
    timestamp: Math.round(new Date().valueOf()),
    appCode: "speedbiz"
  };
  const body = param || {};
  Object.assign(body, constParams);
  return body;
};

export function httpServer(url, params, options = { errorMessage: true }) {
  let obj = {
    url: url,
    method: "post",
    data: verifySign(params),
    options: options
  };
  if (params.method === "get") {
    obj.method = "get";
    obj.params = params;
  }
  if (params.method === "delete") {
    obj.method = "delete";
    obj.params = params;
  }
  return service(obj);
}
