import { getTestPort } from "@/utils/common";
const VUE_APP_URL_API = getTestPort();
const store = {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // 标签列表
    async tagList({ rootState, commit }, params) {
      params.method = "get";
      const data = await rootState.http(VUE_APP_URL_API + "/op/wxcp/external/tag/list", params);
      return data;
    },
    // 任务列表
    async taskList({ rootState, commit }, params) {
      params.method = "get";
      const data = await rootState.http(VUE_APP_URL_API + "/op/wxcp/external/setTagTask/pageList", params);
      return data;
    },
    // 任务日志
    async taskLogList({ rootState, commit }, params) {
      params.method = "get";
      const data = await rootState.http(VUE_APP_URL_API + `/op/wxcp/external/setTagTask/log/pageList`, params);
      return data;
    },
    // 任务详情
    async taskDetail({ rootState, commit }, params) {
      params.method = "get";
      const data = await rootState.http(VUE_APP_URL_API + `/op/wxcp/external/setTagTask/${params.taskId}/detail`, params);
      return data;
    },
    // 任务创建
    async taskCreate({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + `/op/wxcp/external/setTagTask/create`, params);
      return data;
    },
    // 任务删除
    async taskRemove({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + `/op/wxcp/external/setTagTask/${params.taskId}/remove`, params);
      return data;
    },
    // 任务执行
    async taskExecute({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + `/op/wxcp/external/setTagTask/${params.taskId}/execute`, params);
      return data;
    }
  }
};

export default store;
