import locale from "./locale";
const pckContext = require.context("../packages", true, /\.js$/);
const componentsArr = pckContext.keys().map(comPath => {
  return pckContext(comPath).default || pckContext(comPath);
});
// console.log(componentsArr);
const install = (Vue, options = {}) => {
  locale.use(options.locale);
  locale.i18n(options.i18n);
  componentsArr.forEach(comp => {
    Vue.component(comp.componentName, comp);
  });
};
export default {
  install,
  locale: locale.use,
  i18n: locale.i18n,
  ...componentsArr
};
