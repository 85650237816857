import { getTestPort } from "@/utils/common";
const VUE_APP_URL_API = getTestPort();
const store = {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    //分页查询版本列表
    async getVersionList({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/app/basic/queryVersionList", params);
      return data;
    },

    //添加版本
    async addVersion({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/app/basic/addVersion", params);
      return data;
    },

    //修改版本
    async updateVersion({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/app/basic/updateVersion", params);
      return data;
    },

    //查询详细
    async getVersion({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/app/basic/getVersionInfo", params);
      return data;
    },

    //删除
    async deleteVersion({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/app/basic/deleteVersion", params);
      return data;
    }
  }
};

export default store;
