






import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { emptyToLine } from "../../src/utils/common";

@Component({})
export default class SbCapsuleText extends Vue {
  static componentName = "SbCapsuleText";
  @Prop({ default: "66px" })
  width: string;
  @Prop({ default: "1" })
  status: number | string;
  @Prop({ default: "Default" })
  statusDesc: string;
  @Prop({ default: () => ({ "#cccccc": ["1"] }) })
  colorMap: any;
  bgColor: string = "";
  emptyToLine = emptyToLine;
  created() {
    this.setBgColor();
  }
  setBgColor() {
    const colorKeys = Object.keys(this.colorMap);
    for (let index = 0; index < colorKeys.length; index++) {
      const key = colorKeys[index];
      if (this.colorMap[key].includes(this.status)) {
        this.bgColor = key;
        break;
      }
    }
  }
  @Watch("status")
  watchStatus() {
    this.setBgColor();
  }
}
