import { getTestPort } from "@/utils/common";
const VUE_APP_URL_API = getTestPort();
const packageInfo = {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        packageInfoPageQuery({rootState, commit}, params = {}) {
            const data = rootState.http(VUE_APP_URL_API + "/packageInfo/getPageList", params);
            return data;
        },
        packageInfoDetail({rootState, commit}, params = {}) {
            const data = rootState.http(VUE_APP_URL_API + "/packageInfo/detail", params);
            return data;
        },
        savePackageInfo({rootState, commit}, params = {}) {
            const data = rootState.http(VUE_APP_URL_API + "/packageInfo/save", params);
            return data;
        },
        packageInfoSetStatus({rootState, commit}, params = {}) {
            const data = rootState.http(VUE_APP_URL_API + "/packageInfo/setStatus", params);
            return data;
        },
        packageEnableTempLate({rootState, commit}, params = {}) {
            const data = rootState.http(VUE_APP_URL_API + "/packageInfo/queryStoreEnablePackageTemplate", params);
            return data;
        },
        saveStoreEnableServiceItem({rootState, commit}, params = {}) {
            const data = rootState.http(VUE_APP_URL_API + "/packageInfo/saveStoreEnableServiceItem", params);
            return data;
        },
        async getAssignStore({ rootState, commit }, params) {
            params.method = 'get';
            const data = await rootState.http(VUE_APP_URL_API + "/packageInfo/assignStore", params);
            return data;
        },
        async saveAssignStore({ rootState, commit }, params = {}) {
            const data = await rootState.http(VUE_APP_URL_API + "/packageInfo/assignStore", params);
            return data;
        }

    }
}

export default packageInfo;
