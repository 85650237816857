import { getTestPort } from "@/utils/common";
const VUE_APP_URL_API = getTestPort();
const store = {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async statisticsMonthTrend({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/monitoring/statisticsMonthTrend", params);
      return data;
    },
    async queryAddStore({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/monitoring/queryAddStore", params);
      return data;
    },
    async queryActiveStore({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/monitoring/queryActiveStore", params);
      return data;
    },
    async overview({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/monitoring/overview", params);
      return data;
    },
    async newOverview({ rootState },params:any) {
      const data = await rootState.http(`${VUE_APP_URL_API}/newMonitoring/overview?startTime=${params.startTime}&endTime=${params.endTime}`,{method:'get'});
      return data;
    }
  }
};

export default store;
