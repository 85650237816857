import { getTestPort } from "@/utils/common";

const VUE_APP_URL_API = getTestPort();
const store = {
  namespaced: true,
  state: {
    dialog: false
  },
  getters: {},
  mutations: {
    setDialog(state, data) {
      state.dialog = data;
    }
  },
  actions: {
    async queryCustomerCouponList({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opCoupon/store/queryCustomerCouponList", params);
      return data;
    },
    async distributeCoupons({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opCoupon/distribution/distributeCoupons", params);
      return data;
    },
    async queryBrandList({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/vehicleModel/queryBrandList", params);
      return data;
    },
    async queryCustomers({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opCoupon/distribution/queryCustomers", params);
      return data;
    },
    async getDetail({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opCoupon/activity/getDetail", params);
      return data;
    },
    async disable({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opCoupon/activity/disable", params);
      return data;
    },
    // 指定服务无门槛优惠券
    async serviceUnconditional({ rootState }, params = {}) {
      const data = await rootState.http(
        VUE_APP_URL_API + "/opCoupon/activity/save/serviceUnconditional",
        params
      );
      return data;
    },
    // 指定商品满金额优惠券
    async productReachedAmount({ rootState }, params = {}) {
      const data = await rootState.http(
        VUE_APP_URL_API + "/opCoupon/activity/save/productReachedAmount",
        params
      );
      return data;
    },
    // 指定商品无门槛优惠券
    async productUnconditional({ rootState }, params = {}) {
      const data = await rootState.http(
        VUE_APP_URL_API + "/opCoupon/activity/save/productUnconditional",
        params
      );
      return data;
    },
    // 订单满减优惠券
    async orderReachedAmount({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opCoupon/activity/save/orderReachedAmount", params);
      return data;
    },
    // 订单无门槛优惠券
    async orderUnconditional({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opCoupon/activity/save/orderUnconditional", params);
      return data;
    },
    async queryList({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opCoupon/activity/queryList", params);
      return data;
    },
    async queryServiceItemList({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/service/item/queryServiceItemList", params);
      return data;
    },
    async createPayment({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/card/template/addCardTemplate", params);
      return data;
    },

    async manualVerification({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opCoupon/store/manual/verification", params);
      return data;
    },
    async queryUserCouponList({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opCoupon/store/queryUserCouponList", params);
      return data;
    },
  }
};

export default store;
