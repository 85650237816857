import { getTestPort } from "@/utils/common";
const VUE_APP_URL_API = getTestPort();
const VUE_APP_FACTORY_API = process.env.VUE_APP_FACTORY_API;
const store = {
  namespaced: true,
  state: {
    currentTabType: "all"
  },
  getters: {},
  mutations: {
    setCurrentTabType(state, data) {
      state.currentTabType = data;
    }
  },
  actions: {
    async createMaintenanceOrder({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/order/createOrder/maintenance", params);
      return data;
    },
    async updateOrder({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/order/updateOrderInfo", params);
      return data;
    },
    async createWashOrder({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/order/createOrder/carWash", params);
      return data;
    },
    async createRetailOrder({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/order/createOrder/retail", params);
      return data;
    },
    async getOrderList({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opOrder/queryOrder", params);
      return data;
    },
    async getNewOrderList({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_FACTORY_API + "/saas/order/opQueryOrder", params);
      return data;
    },
    async getOrderDetail({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opOrder/getOrder", params);
      return data;
    },
    async getUnpaidOrderList({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/order/getCustomerUnpaidOrder", params);
      return data;
    },
    async settlementOrder({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/receipt/createOrderReceipt", params);
      return data;
    },
    async cancleOrder({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/order/cancelOrder", params);
      return data;
    },
    async exportServiceList({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opOrder/exportOpOrder", params);
      return data;
    }
  }
};

export default store;
